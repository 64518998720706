<template>
    <div class="CattleRoad allw allh flex column relative overflow-hidden">
        <div v-for="(item,index) in Namebox" :key="index" class="flex1 flex">
            <div
                    :class="{'bgblue':(index !==0 && !IsGameIn),'bgred':(index === 0 && !IsGameIn),'hasborder':(index !== 3),'IsGameIn':IsGameIn,'IsChange':IsChange,'NeedTrans':LangeuageAgent !== 'CH'}"
                    :style="{'color':(IsGameIn && index ===0 ? '#f00303' : (IsGameIn && '#435cc8'))}"
                    class="Namebox OnGameCenter flex items-center font20 content-center colorfff relative zindex3">
                <font>{{Lang[item]}}{{index !== 0 ? index : ''}}</font>
            </div>
            <div
                    :style="{'transform':'translate('+(-MoveCount*(IsGameIn && !IsChange ? '0.51' :IsChange ? '0.23' : '0.41'))+'rem,0)'}"
                    class="flex1 transition03">
                <div class="flex1 allh flex absolute">
                    <div v-for="(l,i) in ResultRoadList" :key="i" :class="{'IsGameIn':IsGameIn,'IsChange':IsChange}"
                         class="everyresult allh flex relative">
                        <img :src="'./img/Cattle/'+l[index]['url']+(IsGameIn ? 'a' : '')+'.png'" class="allh allw">
                        <span v-if="l[index]['val'] !== 10" :class="{'IsChange':IsChange}"
                              class="absolute position-center font24 colorfff">{{l[index]['val']}}</span>
                        <img v-else src="../../../public/img/Cattle/l_nn_n9.png"
                             class="allh allw absolute position-center">
                    </div>
                </div>
            </div>
        </div>
        <img @click="MoveTable('left')" :class="{'left100':IsGameIn && !IsChange}" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_left.png">
        <img @click="MoveTable('right')" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_right.png">
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'CattleRoad',
        props: {
            'RoadList': {
                default() {
                    return {}
                }, type: [Array, Object]
            },
            'IsGameIn': [Boolean],
            'IsChange': [Boolean],
            'NeedCreated': [Boolean],
            'DefaultCount': {
                default() {
                    return 10
                }, type: Number
            },
        },
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                Namebox: ['ZJ', 'XJ', 'XJ', 'XJ'],//'庄', '闲1', '闲2', '闲3'
                ResultRoadList: [], //露珠镜像
                MaxX: 0,
                MoveCount: 0
            }
        },
        created() {
            this.$nextTick(() => {
                if (this.$route.name === 'GameHall' || this.IsChange || this.NeedCreated) this.Init();
            });
        },
        watch: {
            'RoadList': {
                handler(n, o) {
                    if (JSON.stringify(n) !== JSON.stringify(o)) this.Init();
                }, deep: true
            },
            'ResultRoadList': {
                handler(n, o) {

                }, deep: true
            }
        },
        methods: {
            Init() {
                let arr = [];
                let fn = (num) => {
                    return Math.abs(num);
                };
                // this.RoadList = [[1, 6, -1, 1]];
                if (Array.isArray(this.RoadList)) {
                    this.RoadList.forEach((item, index) => {
                        let [a, b, c, d] = item;
                        let obj = {
                            '0': {url: 'l_nn_n3', val: fn(a) - 1},
                            '1': {url: 'l_nn_n5', val: fn(b) - 1},
                            '2': {url: 'l_nn_n5', val: fn(c) - 1},
                            '3': {url: 'l_nn_n5', val: fn(d) - 1},
                        };
                        if (fn(a) - 1 === 0) obj['0'].url = 'l_nn_n4';//庄无牛
                        { //闲1
                            if (b < 0) obj['1'].url = 'l_nn_n7';
                            if (fn(b) - 1 === 0) obj['1'].url = 'l_nn_n8';//无牛
                            if (obj['1'].url === 'l_nn_n8' && b > 0) obj['1'].url = 'l_nn_n6';//蓝色圈
                        }
                        { //闲2
                            if (c < 0) obj['2'].url = 'l_nn_n7';
                            if (fn(c) - 1 === 0) obj['2'].url = 'l_nn_n8';//无牛
                            if (obj['2'].url === 'l_nn_n8' && c > 0) obj['2'].url = 'l_nn_n6';//蓝色圈
                        }
                        { //闲3
                            if (d < 0) obj['3'].url = 'l_nn_n7';
                            if (fn(d) - 1 === 0) obj['3'].url = 'l_nn_n8';//无牛
                            if (obj['3'].url === 'l_nn_n8' && d > 0) obj['3'].url = 'l_nn_n6';//蓝色圈
                        }
                        arr.push(obj);
                    });
                }
                this.ResultRoadList = this.Clone(arr);
                this.MaxX = this.ResultRoadList.length;
                if (this.MaxX >= this.DefaultCount) this.MoveCount = this.MaxX - this.DefaultCount;
            },
            MoveTable(Direction) {
                Direction === 'left' && this.MoveCount > 0 && this.MoveCount--;
                Direction === 'right' && Math.abs(this.MoveCount) < this.MaxX - 1 && this.MoveCount++;
            }
        }
    }
</script>

<style lang="less">
    .CattleRoad {
        background: url("../../../public/img/Cattle/l_nn_n2.png") repeat-x top left/contain;

        &:hover {
            > img {
                display: block;
            }
        }

        .bgblue {
            background-color: #4057c3;
        }

        .bgred {
            background-color: #ff0000;
        }

        > div {
            .hasborder {
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #dcdddf;
                    position: absolute;
                    bottom: 0;
                }

                &.IsGameIn {
                    &::after {
                        background-color: rgba(220, 221, 223, 1);
                    }
                }
            }


            .transition03 {
                transition: .3s;
            }

            .Namebox {

                &.OnGameCenter {
                    width: .4rem;

                    &.NeedTrans {
                        font-size: .16rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.4, 1);
                        }
                    }
                }

                &.IsGameIn {
                    width: 1rem;
                    background-color: #ffffff;

                    &.NeedTrans {
                        font-size: .20rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.8, 1);
                        }
                    }
                }

                &.IsChange {
                    width: .45rem;
                    font-size: .14rem;

                    &.NeedTrans {
                        font-size: .12rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.6, 1);
                        }
                    }
                }
            }

            .everyresult {
                pointer-events: none;
                width: .41rem;
                height: .4rem;

                &.IsGameIn {
                    width: .51rem;
                    height: .5rem;
                }

                &.IsChange {
                    width: .23rem;
                    height: .23rem;

                    span {
                        font-size: .14rem;
                    }
                }
            }
        }

        > img {
            z-index: 10;
            width: .3rem;
            height: .3rem;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
            left: .6rem;

            &.left100 {
                left: 1.2rem;
            }

            &:last-child {
                left: auto;
                right: .2rem;
            }
        }
    }
</style>
