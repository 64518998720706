<template>
    <div class="LeftCenter border-box relative allh">
        <img class="list-logo margin-auto mat10" :src="'./img/Login/'+ImgLogo+'.png'">
        <div class="portrait-box margin-auto mat10 relative pointer">
            <img class="allh allw" src="../../../public/img/GameCenter/tx_01.png">
            <img class="absolute" src="../../../public/img/GameCenter/l_xiugai.png">
        </div>
        <div class="info-box colorfff font20">
            <div class="pointer andsoon">
                {{UserInfo.username}}
            </div>
            <div @click="Reflashbalance" class="pointer andsoon">
                {{UserInfo.balance}}
            </div>
        </div>
        <div v-for="(item,index) in navArray" :key="index" @click="RouterTo(item.url)"
             class="nav-box flex column items-center content-between pointer mab10 border-box">
            <img :src="'./img/GameCenter/' + item.src + '.png'" :style="{'height':item.h + 'rem'}">
            <img :src="'./img/GameCenter/' + item.src + '_hover.png'" :style="{'height':item.h + 'rem'}">
            <span :class="[LangeuageAgent === 'CH' ? 'font22' : 'font16']">{{Lang[item.title]}}</span>
        </div>
        <div @click="ControlStatus('ShowGameRecords',true)" :class="[LangeuageAgent === 'CH' ? 'font20' : 'font13']"
             class="game-record mat10 pointer border-box relative">
            <img class="absolute" src="../../../public/img/GameCenter/l_yxjl.png">
            <img class="absolute" src="../../../public/img/GameCenter/l_yxjl_hover.png">
            {{Lang['YXJL']}} <!--游戏记录-->
        </div>
        <div v-if="DefaultConfig.LoginTemplate === 'yhyygj' || DefaultConfig.LoginTemplate === 'hmdylc'"
             @click="ControlStatus('ShowGameRecharge',true)"
             :class="[LangeuageAgent === 'CH' ? 'font20' : 'font12']"
             class="game-record mat10 pointer border-box relative">
            <img class="absolute" style="width: .27rem" src="../../../public/img/GameCenter/l_cztx.png">
            <img class="absolute" style="width: .27rem" src="../../../public/img/GameCenter/l_cztx_hover.png">
            {{Lang['CZTX']}}<!--充值提现-->
        </div>
        <div v-if="this.IsPermission(false) && quickOnlineShowBtn"
             @click="onlineServiceFn"
             :class="[LangeuageAgent === 'CH' ? 'font20' : 'font12']"
             class="game-record mat10 pointer border-box relative" style="padding: 0;text-align: center">
            {{Lang['ZXKF']}}<!--在线客服-->
        </div>
        <div class="footer-box allw text-center">
            <!--            <p :class="[LangeuageAgent === 'CH' ? 'font22' : 'font15']">{{Lang['SMXZ']}}&lt;!&ndash;手机扫码下载&ndash;&gt;</p>-->
            <!--            <img @click="SetStore({'Prop':'ShowGameAppDown',Val:true})" class="mat10 mab10 pointer allw"-->
            <!--                 src="../../../public/img/GameCenter/xiazai_sj.png">-->
            <div :class="[LangeuageAgent === 'CH' ? 'font20' : 'font14']" class="flex content-center items-center">
                <img src="../../../public/img/GameCenter/yonghu_zaixian.png">
                <span class="flex1">{{Lang['ZX']}}<!--在线-->：{{OnlineNum}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import App from "../../main";

    export default {
        name: 'LeftCenter',
        computed: {
            ...mapState(['UserInfo', 'SocketFn', 'DefaultConfig', 'OnlineNum', 'LangeuageAgent','quickOnlineShowBtn'])
        },
        data() {
            return {
                CallBack: ['CallReflashFn'],
                ImgLogo: '',
                navArray: [{
                    title: 'QJT',   //旗舰厅
                    src: 'l_qijianting_btn',
                    h: '0.38',
                    url: 'GameHall',
                }, {
                    title: 'ZXDT',  //自选多台
                    src: 'l_duotai_btn',
                    h: '0.35',
                    url: 'Multiple',
                    // url: '暂未开放',
                },]
            }
        },
        created() {
            this.$nextTick(() => {
                let imgarr = {
                    'bl': 'logo_bl',
                    'szwjt': 'logo_szwjt',
                    'hmdylc': 'logo_center_hmdylc',
                    'dsgj': 'logo_center_dsgj',
                    'yhyygj': 'logo_center_yhyygj',
                    'wzzz': 'logo_center_wzzz',
                    'hjgj': 'logo_center_hjgj',
                    'yl': 'logo_center_yl',
                    'xsj': 'logo_center_yl_xsj',
                    'wfgj': 'logo_center_wfgj',
                };
                this.ImgLogo = imgarr[this.DefaultConfig.LoginTemplate];
            });
        },
        watch: {
            'navArray': {
                handler() {
                }, deep: true
            },
            'UserInfo': {
                handler() {
                }, deep: true
            },
        },
        methods: {
            ControlStatus(Prop, Val) {
                if (!this.IsPermission()) return;
                this.SetStore({
                    Prop, Val
                });
            },
            Reflashbalance() { //刷新余额
                this.SocketApi.SendSock([this.stype.Center, this.cmd.ReBalance, 0, {}], 'CallReflashFn');
            },
            CallReflashFn(res) {
                this.SetStore({
                    Prop: 'UserInfo',
                    AddName: 'balance',
                    Val: res.balance
                });
            },
            onlineServiceFn() {
                this.SetStore({'Prop': 'ShowGameOnline', Val: true})
            }
        }
    }
</script>

<style lang="less">
    .LeftCenter {
        width: 1.8rem;
        padding: 0 .12rem;
        background-color: #140e11;

        .list-logo {
            width: 1.28rem;
            /*margin-bottom: .55rem;*/
            margin-bottom: .25rem;
        }

        .portrait-box {
            width: .95rem;
            height: .95rem;
            text-align: center;

            .absolute {
                width: .28rem;
                height: .28rem;
                top: 0;
                right: 0;
            }
        }

        .info-box {
            margin-top: .35rem;

            div {
                border-radius: .2rem;
                line-height: .40rem;
                padding-left: .1rem;
                padding-right: .45rem;
                margin-bottom: .2rem;

                &:first-child {
                    background: #060606 url("../../../public/img/GameCenter/xiugai.png") no-repeat right center/contain;

                    &:hover {
                        background: #060606 url("../../../public/img/GameCenter/xiugai_hover.png") no-repeat right center/contain;
                    }
                }

                &:last-child {
                    background: #060606 url("../../../public/img/GameCenter/l_left_shuaxin.png") no-repeat right center/contain;

                    &:hover {
                        background: #060606 url("../../../public/img/GameCenter/l_left_shuaxin_hover.png") no-repeat right center/contain;
                    }
                }
            }
        }

        .nav-box {
            height: .8rem;
            background: url("../../../public/img/GameCenter/nav_bg.png") no-repeat center center/cover;
            padding: .1rem 0;

            img:nth-child(2) {
                display: none;
            }

            &:hover {
                background: url("../../../public/img/GameCenter/nav_bg_hover.png") no-repeat center center/cover;

                img:first-child {
                    display: none;
                }

                img:nth-child(2) {
                    display: block;
                }

                span {
                    color: #7a3c14;
                }
            }

            span {
                color: #cecece;
            }
        }

        .game-record {
            line-height: .44rem;
            background: #050505 url("../../../public/img/GameCenter/list_bg.png") no-repeat center center/100% 100%;
            text-align: right;
            padding-right: .2rem;
            color: #cecece;

            img {
                width: .3rem;
                left: .1rem;
                top: 50%;
                transform: translate(0, -50%);
            }

            img:nth-child(2) {
                display: none;
            }

            &:hover {
                background: #050505 url("../../../public/img/GameCenter/list_bg_hover.png") no-repeat center center/100% 100%;
                color: #E3C0A3;

                img:first-child {
                    display: none;
                }

                img:nth-child(2) {
                    display: block;
                }
            }
        }

        .footer-box {
            color: #987866;
            position: absolute;
            bottom: .2rem;
            padding: 0 0.12rem;
            box-sizing: border-box;
            left: 0;

            > img {
                margin-right: auto;
                margin-left: auto;
            }

            div {
                line-height: .45rem;
                background-color: #060606;
                border: solid 1px #f0cfb4;
                color: #b99c8d;

                img {
                    width: .25rem;
                    height: .25rem;
                    margin: 0 .05rem;
                }

                span {
                    text-align: left;
                }
            }
        }
    }
</style>
