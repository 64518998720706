<template>
    <div class="PushBobbinRoad allw allh flex column relative overflow-hidden">
        <div v-for="(item,index) in Namebox" :key="index" class="flex1 flex">
            <div
                    :class="{'bgblue':(index !==0),'bgred':(index === 0),'hasborder':(index !== 4),'IsGameIn':IsGameIn,'IsChange':IsChange,'NeedTrans':LangeuageAgent !== 'CH'}"
                    class="Namebox OnGameCenter flex items-center font20 content-center colorfff relative zindex3">
                <font>{{Lang[item]}}{{index !== 0 ? index : ''}}</font>
            </div>
            <div
                    :style="{'transform':'translate('+(-MoveCount*(IsGameIn && !IsChange ? '0.412' :IsChange ? '0.22' : '0.33'))+'rem,0)'}"
                    class="flex1 transition03">
                <div class="flex1 allh flex absolute">
                    <div v-for="(l,i) in ResultRoadList" :key="i"
                         :class="{'IsGameIn':IsGameIn,'IsChange':IsChange,'bgz':index === 0,'bgwin':index !==0 && l[index]['state']}"
                         class="everyresult allh flex relative border-box">
            <span v-if="l[index]['val'] !== 10"
                  :class="{'IsChange':IsChange,'NeedTrans':Math.abs(l[index]['num']) > 10 && LangeuageAgent !== 'CH'}"
                  class="absolute position-center font13 colorfff">{{l[index]['val']}}</span>
                    </div>
                </div>
            </div>
        </div>
        <img @click="MoveTable('left')" :class="{'left100':IsGameIn && !IsChange}" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_left.png">
        <img @click="MoveTable('right')" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_right.png">
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'PushBobbinRoad',
        props: {
            'RoadList': {
                default() {
                    return {}
                }, type: [Array, Object]
            },
            'IsGameIn': [Boolean],
            'IsChange': [Boolean],
            'NeedCreated': [Boolean],
            'DefaultCount': {
                default() {
                    return 10
                }, type: Number
            },
        },
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                Namebox: ['ZJ', 'TTZT', 'TTZT', 'TTZT', 'TTZT'],//'庄', '筒1', '筒2', '筒3', '筒4'
                ResultRoadList: [], //露珠镜像
                MaxX: 0,
                MoveCount: 0
            }
        },
        created() {
            this.$nextTick(() => {
                if (this.$route.name === 'GameHall' || this.IsChange || this.NeedCreated) this.Init();
            });
        },
        watch: {
            'RoadList': {
                handler(n, o) {
                    if (JSON.stringify(n) !== JSON.stringify(o)) {
                        this.Init();
                    }
                }, deep: true
            },
            'ResultRoadList': {
                handler(n, o) {

                }, deep: true
            }
        },
        methods: {
            Init() {
                let arr = [];
                let resultObj = {
                    '1': '0',
                    '2': '1',
                    '3': '2',
                    '4': '3',
                    '5': '4',
                    '6': '5',
                    '7': '6',
                    '8': '7',
                    '9': '8',
                    '10': '10',
                    '11': '对一',
                    '12': '对二',
                    '13': '对三',
                    '14': '对四',
                    '15': '对五',
                    '16': '对六',
                    '17': '对七',
                    '18': '对八',
                    '19': '对九',
                    '20': '天对',
                };
                let NeedTransForm = {
                    '11': 'Phải 1',
                    '12': 'Phải 2',
                    '13': 'Phải 3',
                    '14': 'Phải 4',
                    '15': 'Phải 5',
                    '16': 'Phải 6',
                    '17': 'Phải 7',
                    '18': 'Phải 8',
                    '19': 'Vâng 9',
                    '20': 'Vâng 10',
                };
                let fn = (num) => {
                    if (this.LangeuageAgent !== 'CH' && Math.abs(num) > 10) {
                        return NeedTransForm[Math.abs(num)];
                    }
                    return resultObj[Math.abs(num)];
                };
                let fn1 = (num) => {
                    return num > 0;
                };
                // this.RoadList = [[1, 9, -1, 10],[1, 6, -1, 10],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 10],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1],[1, 6, -1, 1]];
                if (Array.isArray(this.RoadList)) {
                    this.RoadList.forEach((item, index) => {
                        let [a, b, c, d, e] = item;
                        let obj = {
                            '0': {state: fn1(a), val: fn(a), num: a},
                            '1': {state: fn1(b), val: fn(b), num: b},
                            '2': {state: fn1(c), val: fn(c), num: c},
                            '3': {state: fn1(d), val: fn(d), num: d},
                            '4': {state: fn1(e), val: fn(e), num: e},
                        };
                        arr.push(obj);
                    });
                }
                this.ResultRoadList = this.Clone(arr);
                this.MaxX = this.ResultRoadList.length;
                if (this.MaxX >= this.DefaultCount) this.MoveCount = this.MaxX - this.DefaultCount;
            },
            MoveTable(Direction) {
                Direction === 'left' && this.MoveCount > 0 && this.MoveCount--;
                Direction === 'right' && Math.abs(this.MoveCount) < this.MaxX - 1 && this.MoveCount++;
            }
        }
    }
</script>

<style lang="less">
    .PushBobbinRoad {
        background: url("../../../public/img/BobbinRoad/xz_ttz_l_t2.png") repeat-x -0.095rem top/contain;

        &:hover {
            > img {
                display: block;
            }
        }

        .bgblue {
            background-color: #4057c3;
        }

        .bgred {
            background-color: #ff0000;
        }

        > div {
            .hasborder {
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #dcdddf;
                    position: absolute;
                    bottom: 0;
                }

                &.IsGameIn {
                    &::after {
                        background-color: rgba(220, 221, 223, 1);
                    }
                }
            }


            .transition03 {
                transition: .3s;
            }

            .Namebox {

                &.OnGameCenter {
                    width: .55rem;

                    &.NeedTrans {
                        font-size: .16rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.7, 1);
                        }
                    }
                }

                &.IsGameIn {
                    width: .7rem;
                    /*background-color: #ffffff;*/

                    &.NeedTrans {
                        font-size: .20rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.8, 1);
                        }
                    }
                }

                &.IsChange {
                    width: .45rem;
                    font-size: .14rem;

                    &.NeedTrans {
                        font-size: .12rem;
                        white-space: nowrap;

                        font {
                            transform: scale(.8, 1);
                        }
                    }
                }
            }

            .everyresult {
                border-radius: 50%;
                width: .33rem;
                height: .33rem;
                border: 0.5px solid #ffffff;
                background-color: #7d7d7d;

                &.bgz {
                    background-color: #c31616;
                }

                &.bgwin {
                    background-color: #435cc8;
                }

                span {
                    white-space: nowrap;

                    &.NeedTrans {
                        transform: scale(.6) translate(-80%, -80%);
                    }
                }

                &.IsGameIn {
                    width: .412rem;
                    height: .4rem;
                }

                &.IsChange {
                    width: .22rem;
                    height: .22rem;

                    span {
                        font-size: .2rem;
                        transform: scale(.5) translate(-100%, -100%);

                        &.NeedTrans {
                            transform: scale(0.25) translate(-200%, -200%) !important;
                        }
                    }
                }
            }
        }

        > img {
            z-index: 10;
            width: .3rem;
            height: .3rem;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
            left: .6rem;

            &.left100 {
                left: 1.2rem;
            }

            &:last-child {
                left: auto;
                right: .2rem;
            }
        }
    }
</style>
